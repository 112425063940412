<template>
	<div class="news-single row g-5">
		<h1 class="news-single--title mt-7">
			{{ data.detail.title }}
		</h1>
		<div class="news-single--post">
			<span
				v-if="data.detail.datetime.length > 0"
				class="news-single--datetime"
			>
				{{ formatDate(data.detail.datetime) }}
			</span>

			<span
				class="news-single--categories"
				v-if="data.detail.categories.length > 0"
			>
				<span
					v-for="(category, index) in data.detail.categories"
					:key="index"
					class="categories-title"
				>
					<span>{{ category.title }}</span>
					<span v-if="index + 1 < data.detail.categories.length"
						>,
					</span>
				</span>
			</span>
		</div>
		<!--
		<p
			v-if="data.detail.teaser.length"
			class="news-single--teaser"
		 >
			{{data.detail.teaser}}
		</p> -->

		<div class="col-12">
			<div class="news-single--image mt-3 mb-3">
				<img
					v-if="getFirstImageSrc()"
					:src="getFirstImageSrc()"
					:alt="getFirstImageProperties().alternative"
					class="lazyload"
				/>
			</div>
		</div>

		<div class="col-12">
			<div v-html="data.detail.bodytext"></div>
			<div v-if="data.contentElements">
				<ce-renderer :content="data.contentElements" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NewsSingle',
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	watch: {
		bodytext: 'contentUpdated',
	},
	mounted() {
		this.$nextTick(this.addListeners)
	},
	beforeDestroy() {
		this.removeListeners()
	},
	methods: {
		//INFO found a way to define the methods as mixins
		getSingleImages() {
			return (
				this.data.detail?.media ||
				[
					/* empty array */
				]
			)
		},
		getFirstImageSrc() {
			const images = this.getSingleImages()
			return (
				images[0]?.images?.detailViewImage?.publicUrl ||
				images[0]?.images?.defaultImage?.publicUrl ||
				null
			)
		},
		getFirstImageProperties() {
			const images = this.getSingleImages()
			return (
				images[0]?.properties ||
				{
					/* empty object */
				}
			)
		},
		navigate(event) {
			let target = event.target
			let i = 0
			// Go throught 5 parents max to find a tag
			while (
				i < 5 &&
				!(target instanceof HTMLAnchorElement) &&
				target.parentNode
			) {
				target = target.parentNode
				i++
			}
			// If target is still not a link, ignore
			if (!(target instanceof HTMLAnchorElement)) return
			return this.redirect(target)
		},
		redirect(target) {
			const href = target.getAttribute('href')
			// Get link target, if local link, navigate with router link
			if (href && href[0] === '/') {
				event.preventDefault()
				this.$router.push(href)
			}
		},
		contentUpdated() {
			this.removeListeners()
			this.$nextTick(() => {
				this.addListeners()
			})
		},
		addListeners() {
			this._links = this.$el.getElementsByTagName('a')
			for (let i = 0; i < this._links.length; i++) {
				// p2media begin
				if (this._links[i].href) {
					this._links[i].href = this._links[i].href.replace(
						/^(https?:\/\/[^\/]+)?(\/)?api\/(.+)\.json$/,
						'$1$2$3'
					)
				}
				// p2media end

				this._links[i].addEventListener('click', this.navigate, false)
			}
		},
		removeListeners() {
			if (this._links) {
				for (let i = 0; i < this._links.length; i++) {
					this._links[i].removeEventListener(
						'click',
						this.navigate,
						false
					)
				}
				this._links = []
			}
		},
		formatDate(dateStr) {
			const months = {
				Jan: '01',
				Feb: '02',
				Mar: '03',
				Apr: '04',
				May: '05',
				Jun: '06',
				Jul: '07',
				Aug: '08',
				Sep: '09',
				Oct: '10',
				Nov: '11',
				Dec: '12',
			}
			const parts = dateStr.split(' ')
			return `${parts[1]}.${months[parts[0]]}.${parts[2]}`
		},
	},
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
$news-single--post-color: color(primary, light) !default;

.news-single {
	.frame-default {
		padding-bottom: 1.5rem !important;
	}
	// h2 {
	// 	margin-top: 3.875rem;
	// }
	.news-single--image img {
		width: 100%;
	}
	.news-single--title,
	.news-single--teaser {
		text-align: center;
	}

	.news-single--post {
		display: flex;
		justify-content: center;
	}

	.news-single--datetime,
	.news-single--categories {
		margin-top: 0;
		text-align: center;
		font-weight: 800;
		color: $news-single--post-color;
	}

	.news-single--categories {
		&:before {
			content: '●';
			margin: 0 10px;
		}
	}

	.news-single--subheadline {
		@include responsive-type(5, 1);
		margin-bottom: unit(7);
	}
	.layout-image_news_smaller_left {
		@include media-breakpoint-up(lg) {
			.ce-gallery__col {
				width: 40%;
				float: left;
			}
		}

		.ce-gallery__row {
			display: block;
		}
		.ce-gallery--vertical-intext:not(.ce-gallery--no-wrap).ce-gallery--horizontal-left
			.ce-gallery__container {
			float: none;
		}
	}
	.layout-image_news_smaller_center {
		.ce-gallery__col {
			margin: 0 auto;
		}
		.ce-gallery .ce-gallery--horizontal-center .ce-gallery--vertical-above {
			.ce-gallery__col {
				margin-bottom: 1rem;
			}
		}

		@include media-breakpoint-up(lg) {
			.ce-gallery__col {
				width: 60%;
			}
		}
	}
}
</style>
